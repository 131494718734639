<template>
    <div class="components-contract-box">
        <div class="components-contract">
            <div class="font-icon" @click="closeAlert">
                <span class="iconfont">&#xe67d;</span>
            </div>
            <div v-if="alertStatus == 3" style="width 100%;height:240px;" id="contractQuantityZuJian"></div>
            <div v-if="alertStatus == 4" style="width 100%;height:240px;" id="estimatedSalesZuJian"></div>
            <div v-if="alertStatus == 5" style="width 100%;height:240px;" id="contractNumberZuJian"></div>
            <div v-if="alertStatus == 6" style="width 100%;height:240px;" id="contractEstimateZuJian"></div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../../utils';
export default {
    data() {
        return {
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],

            tabListDataTwo: [],
            tabListDataTwoName: [],
            tabListDataTwoList: [],
            tabListDataTwoDate: [],
            tabListEchartsHtTwo: [],
            tabListEchartsHtOne: [],

        };
    },
    props: {
        alertStatus: {
            type: [Number, String],
        },
    },
    mounted() {
        if (this.alertStatus === 3 || this.alertStatus === 4) {
            this.processingCenter();
        } else if (this.alertStatus === 5 || this.alertStatus === 6) {
            this.bySalesman();
        }
    },
    methods: {
        closeAlert() {
            this.$emit('closeChildAlert');
        },
        // 合同个数 3
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityZuJian'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                    padding: [0, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            myChart.setOption(option, true);
        },
        // 预计销售方量 4
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesZuJian'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                    padding: [0, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            myChart.setOption(option, true);
        },
        // 业务员销售合同数量统计 5
        contractNumberFn(data) {
            const myChart = this.$echarts.init(document.getElementById('contractNumberZuJian'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEchartsHtTwo,
                        axisLabel: {
                            color: '#666666',
                            interval: 0,
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: {
                    type: 'bar',
                    data: data,
                    label: {
                        fontFamily: 'QuartzLTStd',
                        show: true,
                        position: 'top',
                        color: '#2E9BFC',
                        fontSize: '14',
                        padding: 5,
                        // backgroundColor: {
                        //     image: addBgImages(1, 'top'),
                        // },
                    },
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: '#3E78C3', // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#44A1F5', // 100% 处的颜色
                            }], false),
                        },
                    },
                },
            };
            myChart.setOption(option, true);
        },
        // 业务员销售预计方量统计 6
        contractEstimateFn(data) {
            const myChart = this.$echarts.init(document.getElementById('contractEstimateZuJian'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                // },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListEchartsHtOne,
                        axisLabel: {
                            color: '#666666',
                            interval: 0,
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: 'bar',
                        data: data,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            color: '#FE9E1F',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 处理中心
        processingCenter() {
            this.$axios
                .get('/interfaceApi/report/tjfx/xsht/months/type?date=&datenum=12')
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return {
                            name: item,
                            list: arr,
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 合同数量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.htsl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 预计销售方量
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.yjxsfl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 合同数量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.htsl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 预计销售方量合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.yjxsfl * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    if (this.alertStatus === 3) {
                        // 合同数量图表
                        this.contractQuantityCh(htCompleteData);
                    } else if (this.alertStatus === 4) {
                        // 预计销售方量图表
                        this.estimatedSalesCh(flCompleteData);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 按业务员
        bySalesman() {
            this.$axios
                .get('/interfaceApi/report/tjfx/xsht/months/salesman?date=&datenum=12')
                .then(res => {
                    this.tabListDataTwo = res;

                    this.tabListDataTwoList = res.list;
                    this.tabListDataTwoDate = res.date;
                    // 表格渲染
                    this.tabListDataTwoName = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return {
                            name: item,
                            list: arr,
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHtTwo = res.name;
                    this.tabListEchartsHtOne = res.yjxsflname;
                    // 合同
                    const htTotalData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.htsl * 100) / 100;
                        });
                        return total;
                    });
                    // 预计销售方量合计
                    const flTotalData = res.yjxsflname.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.yjxsfl * 100) / 100;
                        });
                        return total;
                    });
                    if (this.alertStatus === 5) {
                        // 业务员销售
                        this.contractNumberFn(htTotalData);
                    } else if (this.alertStatus === 6) {
                        // 预计销售方量图表
                        this.contractEstimateFn(flTotalData);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus' scoped>
.components-contract-box
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    display flex
    z-index 99
    .components-contract
        background #fff
        width 80%
        padding 0 0 .4rem
        height 4.2rem
        margin-top 1.5rem
        margin-left 2rem
        .font-icon
            width 100%
            display flex
            justify-content flex-end
            padding-right .6rem
            padding-top .2rem
            span
                width .3rem
                height .3rem
                display flex
                justify-content center
                align-items center
                color #1577D2
                border 1px solid #D7D7D7
                font-size .16rem
                cursor pointer
</style>